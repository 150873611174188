import { AnimatePresence, motion } from 'framer-motion'
import { useToggle } from 'react-use'
import { Chevron } from '@vhl/icons'
import { Book } from '@vhl/icons/post'
import * as styles from '../styles/references.module.css'

const References = ({ references }: any) => {
  const [on, onToggle] = useToggle(false)
  return (
    <div className={styles.references}>
      <div
        className="flex justify-between items-center px-5 py-[10px] border-[1px] cursor-pointer"
        onClick={onToggle}
      >
        <div className="flex items-center space-x-4">
          <Book />
          <h5 className="heading-6 md:subheading-5">Nguồn tham khảo</h5>
        </div>
        <span className="relative h-full">
          <Chevron
            className={`absolute -top-3 -left-8 ${on &&
              '-rotate-180'} transform transition duration-300`}
            width={48}
            height={32}
          />
        </span>
      </div>
      <AnimatePresence exitBeforeEnter>
        {on && (
          <motion.div
            className="px-5 py-[10px] border-[1px] border-t-0 caption prose max-w-none text-gray-800"
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.15 }}
          >
            <div dangerouslySetInnerHTML={{ __html: references }} />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default References
