import { SVGProps } from 'react'

const ShareIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M24.5 11.166a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM8.5 20.5a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM24.5 29.834a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM11.953 18.514l9.107 5.306M21.047 9.18l-9.094 5.306"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default ShareIcon
