import { SVGProps } from 'react'

const ListIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" {...props}>
    <path
      d="M11.832 9h18.417M11.832 17.5h18.417M11.832 26h18.417"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M4.75 9h.014M4.75 17.5h.014M4.75 26h.014" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export default ListIcon
