import { SVGProps } from 'react'

const BookIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2.66602 4H10.666C12.0805 4 13.4371 4.5619 14.4373 5.5621C15.4374 6.56229 15.9993 7.91885 15.9993 9.33333V28C15.9993 26.9391 15.5779 25.9217 14.8278 25.1716C14.0776 24.4214 13.0602 24 11.9993 24H2.66602V4Z"
      stroke="#2C2B2B"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.3333 4H21.3333C19.9188 4 18.5623 4.5619 17.5621 5.5621C16.5619 6.56229 16 7.91885 16 9.33333V28C16 26.9391 16.4214 25.9217 17.1716 25.1716C17.9217 24.4214 18.9391 24 20 24H29.3333V4Z"
      stroke="#2C2B2B"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default BookIcon
