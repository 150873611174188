function LikeIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9.335 29.333h-4a2.667 2.667 0 01-2.667-2.667v-9.333a2.667 2.667 0 012.667-2.667h4m9.333-2.667V6.666a4 4 0 00-4-4l-5.333 12v14.667h15.04a2.666 2.666 0 002.666-2.267l1.84-12a2.668 2.668 0 00-2.666-3.067h-7.547z"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default LikeIcon
