function LinkIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13.332 17.334a6.668 6.668 0 0010.053.72l4-4a6.666 6.666 0 00-9.426-9.427l-2.294 2.28"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.667 14.668a6.666 6.666 0 00-10.053-.72l-4 4a6.667 6.667 0 009.426 9.426l2.28-2.28"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default LinkIcon
